import * as React from 'react';
import {FormEvent} from 'react';
import {Form} from "react-bootstrap";
import copy = require("copy-to-clipboard");
import FacebookLogin from 'react-facebook-login';

//@ts-ignore
import LogoImg from '../img/logo.svg';

interface Props {
    loading: boolean;
    setLoading: (value: boolean) => any;
    setCode: (value: string) => any;
    code: string;
}

const sharableUrl = 'http://bit.ly/connect_2021';
const sharable = code => `${code} \n #كونكت_2021 \n\n ${sharableUrl}`
const sharableEncoded = (code) => encodeURIComponent(sharable(code)).replace('#', '%23');

const canShare = !!navigator.share;

export const GetCode = ({code, setCode, loading, setLoading}: Props) => {

    const cities = [
        'بنغازي ',
        'طرابلس',
        'قمينس ',
        'سلوق ',
        'النواقيه ',
        'المرج ',
        'البيضاء ',
        'شحات ',
        'سوسه ',
        'القبة ',
        'درنة ',
        'التميمي ',
        'ام الرزم ',
        'طبرق',
    ];

    const getCode = async (user?: FormData) => {
        try {
            let body: any = {};
            if (user) {
                // body = user;
                user.forEach((v, k) => {
                    body[k] = v;
                });
            } else {
                //@ts-ignore;
                body.token = window.token;
            }
            const response = await fetch('https://services-api.connect.ly/ram.php', {
                method: 'post',
                body: JSON.stringify(body)
            }).then(res => res.json());
            console.log(response);
            if (!response.status) {
                if (response.data === 'login') {
                    window.location.href = 'https://my-test.connect.ly';
                    sessionStorage.removeItem('x');
                }
                throw response;
            }

            return (response.data ?? '').toUpperCase();

        } catch (err) {
            throw err;
        }
    }

    let [copyMessage, setCopyMessage] = React.useState(false);
    let [connectUser, setConnectUser] = React.useState(false);

    React.useEffect(() => {
        // @ts-ignore
        if (!window.token) {
            setConnectUser(false)
            return;
        }
        setConnectUser(true);
        setLoading(true);
        getCode().then(c => {
            setCode(c);
        }).finally(() => setLoading(false));
    }, []);

    const share = () => {
        navigator.share({
            text: sharable(code),
            url: sharableUrl,
        })
    };

    let timeout;
    const copyCode = (event) => {
        event.preventDefault();
        if (timeout) {
            clearTimeout(timeout);
        }
        setCopyMessage(true);
        copy(sharable(code.toUpperCase()), {format: 'text/plain'});
        timeout = setTimeout(() => setCopyMessage(false), 2000);
    };

    const submitUser = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        getCode(new FormData(e.target as HTMLFormElement))
            .then(c => {
                setCode(c);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const facebookResponse = (response) => {
        // console.log(response)
        if (response.accessToken) {
            window.open(`https://www.facebook.com/share.php?display=page&u=${encodeURIComponent(sharableUrl)}&quote=${sharableEncoded(code)}`, '_blank')
        }
    }


    return (
        <>
            {loading ? <></> : code ? <>
                <h3 id="copy-the-code"> انسخ الكود و شاركه مع هاشتاق #كونكت_2021</h3>
                <h1 id="code" className="mx-auto" style={{cursor: 'pointer'}} onClick={copyCode}>{code}</h1>
                <div id="copied" className={"font-weight-bold " + (copyMessage ? 'done' : '')}>تم النسخ!</div>
                <hr/>
                {canShare ? <button className="btn btn-outline-secondary btn-lg" onClick={() => share()}>نشر
                    الكود!</button> : <></>}
                <button className="btn btn-outline-primary border-0 mx-1" onClick={copyCode} id="clipboard">
                    <i className="fas fa-copy fw fa-2x"></i>
                </button>
                {/*<a className="btn btn-outline-primary border-0 mx-1"*/}
                {/*   href={`https://www.facebook.com/share.php?display=page&u=${encodeURIComponent(sharableUrl)}&quote=${sharableEncoded(code)}`}*/}
                {/*   target="_blank"*/}
                {/*   id="facebook">*/}
                {/*    <i className="fab fa-facebook fw fa-2x"></i>*/}
                {/*</a>*/}
                <FacebookLogin
                    appId="1096493927519959"
                    textButton={''}
                    callback={facebookResponse}
                    cssClass="btn btn-outline-primary border-0 mx-1"
                    icon={<i className="fab fa-facebook fw fa-2x"></i>}
                    />
                <a className="btn btn-outline-primary border-0 mx-1"
                   href={`https://twitter.com/intent/tweet?text=${sharableEncoded(code)}`} target="_blank" id="twitter">
                    <i className="fab fa-twitter fw fa-2x"></i>
                </a>
            </> : connectUser ? <></> : <>
                <h3 className="text-center">عبي بياناتك</h3>
                <Form onSubmit={submitUser}>
                    <Form.Group controlId="name">
                        <Form.Control placeholder="اسمك" required name="name"/>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Control type="tel" placeholder="رقم هاتفك" required pattern="09[0-9]{8}" name="phone"/>
                    </Form.Group>
                    <Form.Group controlId="city">
                        <Form.Control as="select" placeholder="اسمك" name="city">
                            {
                                cities.map((city) => <option key={city} value={city}>{city}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                    <button className="btn btn-primary">حصول على كود المسابقة</button>
                </Form>
                <hr/>
                <a className="btn btn-link text-secondary w-100"
                   href="https://my-test.connect.ly/login?redirect_url=http%3A%2F%2Fcampaign2021.connect.ly%3Fx%3D%7Btoken%7D">اشتراك
                    كمستخدم كونكت<img src={LogoImg} style={{height: '3em'}}/></a>
            </>}
        </>
    );
}
