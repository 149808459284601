import {android, ios} from 'platform-detect'

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from "./app/App";

if (android) {
    document.body.classList.add('os-android');
}

if (ios) {
    document.body.classList.add('os-ios');
}

const urlParams = new URLSearchParams(window.location.search);
window.history.replaceState({}, document.title, "/");
if (urlParams.get('x')) {
    sessionStorage.x = urlParams.get('x')?.toString() || '';
}
// @ts-ignore
window.token = sessionStorage.x;

// console.log(ReactDOM);
// ReactDOM.re
ReactDOM.render(<App/>, document.getElementById('root'));

