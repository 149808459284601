import * as React from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";

// @ts-ignore
import PrizesImage from '../img/prizes.png';
// @ts-ignore
import LogoMinimal from '../img/logo-minimal.svg';
import {GetCode} from "./GetCode";
import {CheckCode} from "./CheckCode";

export const App = () => {

    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [code, setCode] = React.useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="container-fluid">
                <div className="container d-flex flex-column py-5 h-100vh" style={{zIndex: 2}}>
                    <div className="w-100 flex-grow-1" style={{
                        height: '50vh', backgroundImage: `url(${PrizesImage})`,
                        backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}/>
                    <button onClick={handleShow} className="btn btn-secondary btn-lg mx-auto my-3">شارك في المسابقة</button>
                    <img className="mx-auto my-2" style={{height: '25px'}} src={LogoMinimal}/>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered backdrop="static">
                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>Modal heading</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body>
                    <div className="text-center">
                        {loading ? <Spinner animation="grow"/> : null}
                        <GetCode
                            loading={loading} setLoading={(v) => setLoading(v)}
                            code={code} setCode={(v) => setCode(v)}/>
                    </div>
                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*    <Button variant="secondary" onClick={handleClose}>*/}
                {/*        Close*/}
                {/*    </Button>*/}
                {/*    <Button variant="primary" onClick={handleClose}>*/}
                {/*        Save Changes*/}
                {/*    </Button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        </>
    );
}
